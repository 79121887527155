import React from "react";

const CrimesInDenver = () => {
  return (
    <div>
      <iframe
        src="/Crimes-in-Denver.html"
        style={{ width: "100%", height: "800px", border: "none" }}
        title="Dashboard"
      ></iframe>
    </div>
  );
};

export default CrimesInDenver;
