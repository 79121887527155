const Data = [
  {
    id: 1,
    position: "Manager of Mobility Services Data",
    company: "Central Florida Regional Transportation Authority (LYNX)",
    location: "Orlando, FL",
    dates: "10/2021 – Current",
    responsibilities: [
      "Manage cross-functional projects using advanced data monitoring and analysis techniques for strategic decision-making and operational performance improvement.",
      "Ensure compliance with ADA and other regulations through audits and operations data analysis.",
      "Develop reporting criteria and monitored service delivery and performance.",
      "Resolve service issues by recommending operational efficiency solutions.",
      "Manage operating and capital budgets.",
      "Provides data analysis and support to prepare the AOR (Annual Operating Report) and NTD (National Transit Database) reports.",
    ],
  },
  {
    id: 2,
    position: "Executive Director of Operations",
    company: "Trans Voyage Taxi",
    location: "Aurora, CO",
    dates: "08/2018 - Current",
    responsibilities: [
      "Enhanced business success by significantly increasing profit margins through the strategic design, implementation, and continuous optimization of business processes",
      "Strengthened client relationships and operational efficiency, leading to a successful product launch and significant company growth with a detailed go-to-market strategy.",
      "Directed comprehensive HR operations, enhancing organizational efficiency through strategic workforce planning and performance management.",
      "Improved operational productivity and efficiency by managing financial budgets and implementing cost control measures, leading to reduced operational risks and accidents.",
      "Spearheaded recruitment, training, and development of initial personnel, fostering a culture of excellence and continuous improvement.",
      "Utilized business intelligence tools to measure industry trends, enabling competitive positioning within Colorado's market.",
      "Drove business growth and strategic planning, ensuring regulatory compliance and achieving no adverse audit findings.",
      "Championed a modern employee recognition program, significantly boosting productivity and morale.",
    ],
  },
  {
    id: 3,
    position: "Talent Acquisition Manager",
    company: "Metro Transportation Planning And Solutions Group",
    location: "Denver, CO",
    dates: "04/2017 - 05/2018",
    responsibilities: [
      "Developed and executed a comprehensive communication and marketing plan to attract top talent, leveraging diverse sourcing channels including employee referrals, direct sourcing, and recruitment agencies.",
      "Enhanced talent acquisition processes, focusing on internal mobility, emerging talent, and creating performance metrics to drive organizational change and assess effectiveness.",
      "Served as a brand ambassador, highlighting the company's culture, career growth opportunities, and benefits to educate and attract candidates.",
      "Introduced tailored onboarding solutions and collaborated closely with the COO to develop training materials that align with the company’s culture and performance goals.",
      "Implemented strategic talent acquisition and job placement strategies, emphasizing diversity and optimizing sourcing networks to proactively identify and engage qualified candidates.",
    ],
  },
  {
    id: 4,
    position: "General Manager",
    company: "Green Taxi Cooperative",
    location: "Denver, CO",
    dates: "03/2016 - 04/2017",
    responsibilities: [
      "Instrumental in the startup phase, establishing departments and meeting employment needs.",
      "Utilized performance data to enhance operations, adapting to market trends for operational excellence.",
      "Developed and executed business strategies, improving operational quality and efficiency.",
      "Managed financials, from budgeting to P&L oversight, aligning with growth objectives.",
      "Innovated marketing strategies, significantly enhancing brand positioning.",
      "Ensured compliance through diligent audit oversight.",
      "Unified essential business systems to boost overall productivity.",
      "Cultivated community relations to explore business opportunities.",
      "Implemented quality assurance standards to ensure customer satisfaction.",
      "Authored key operational manuals, fostering a cohesive organizational culture.",
    ],
  },
  {
    id: 5,
    position: "General Manager",
    company: "Mile High Cab, Inc",
    location: "Denver, CO",
    dates: "02/2014 - 03/2016",
    responsibilities: [
      "Leveraged performance data to refine operations and align with evolving business and market requirements.",
      "Formulated and executed business strategies to enhance operational quality and team productivity.",
      "Innovated solutions by analyzing market trends, improving service delivery and operational efficiency.",
      "Managed financials meticulously, achieving targets through budget oversight and P&L management.",
      "Enhanced productivity by integrating key systems, streamlining payroll, accounting, and material planning.",
      "Mitigated risk by optimizing inventory management and shrinkage control.",
      "Conducted comprehensive employee management, including performance reviews, training, and scheduling.",
      "Applied quality assurance standards to ensure superior customer experiences.",
    ],
  },
  {
    id: 6,
    position: "General Store Manager",
    company: "Ralston Convenience Store",
    location: "Arvada, CO",
    dates: "08/2012 - 07/2014",
    responsibilities: [
      "Orchestrated weekly meetings to set sales and service objectives, enhancing team alignment and performance.",
      "Utilized performance data for continuous operational improvements, addressing real-time business needs.",
      "Developed and enforced policies to boost efficiency and adaptability, with strategic scheduling to meet customer demands.",
      "Enhanced team dynamics and productivity through a culture of collaboration and mutual success.",
      "Assessed supplier performance for cost efficiency, contributing to operational excellence.",
      "Directed sales strategies and budget management, achieving financial targets and sustainability.",
      "Led the development of a new store, from location selection to team building, ensuring customer service excellence.",
      "Implemented security measures to safeguard assets, alongside innovative strategies for inventory management and customer feedback utilization.",
    ],
  },
];

export default Data;

