import React from "react";

const Airlines = () => {
  return (
    <div>
      <iframe
        src="/invesitco.pdf"
        style={{ width: "100%", height: "800px", border: "none" }}
        title="invistcoairlines"
      ></iframe>
    </div>
  );
};

export default Airlines;
