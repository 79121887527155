import React from "react";

const AIcomplaint = () => {
  return (
    <div>
      <iframe
        src="https://complaint-svn9.onrender.com/"
        style={{ width: "100%", height: "800px", border: "none" }}
        title="Dashboard"
      ></iframe>
    </div>
  );
};

export default AIcomplaint;
